import React from 'react'
import { Link } from 'gatsby'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
import Subject from '@material-ui/icons/Subject'

import Electricidad from "assets/img/sections/electricista.jpg";
import Nave from "assets/img/sections/nave-industrial.jpg";
import Mantenimiento from "assets/img/sections/mantenimiento-electricista.jpg";
import Cargador from "assets/img/sections/cargador-coche-electrico.jpg";
import Aire from "assets/img/sections/aire-acondicionado.jpg";
import { CARGADORES, DOMESTICO, INDUSTRIAL, MANTENIMIENTO, AIRE } from 'constants/routes';

const CardLayout = ({ classes, href, title, desc, img }) => {
  return (
    <GridItem xs={12} md={6} lg={6}>
      <Card background style={{ backgroundImage: `url(${img})` }}>
        <Link to={href}>
          <CardBody background>
            <h3 className={classes.cardTitleWhite}>{title}</h3>
            <p className={classes.cardDescriptionWhite}>{desc}</p>
            <Button round color="danger">
              Entrar
            </Button>
          </CardBody>
        </Link>
      </Card>
    </GridItem>
  )
}

const LandingServices = ({ classes }) => {
  return (
    <div className={classes.wrapper}>
      <GridContainer>
      
        <GridItem xs={12}>
          <h2 style={{fontWeight: "bold"}}>Nuestros Servicios</h2>
        </GridItem>      

        <CardLayout 
          classes={classes}
          img={Electricidad}
          href={DOMESTICO}
          title="Instalaciones eléctricas en pisos, locales comerciales y comunidades de vecinos"
          desc=''
        />

        <CardLayout 
          classes={classes}
          img={Nave}
          href={INDUSTRIAL}
          title="Instalaciones eléctricas industriales"
          desc=""
        />

        <CardLayout 
          classes={classes}
          img={Mantenimiento}
          href={MANTENIMIENTO}
          title="Mantenimiento y averías"
          desc=""
        />

        <CardLayout 
          classes={classes}
          img={Cargador}
          href={CARGADORES}
          title="Cargadores de coches eléctricos"
          desc=""
        />

        <CardLayout 
          classes={classes}
          img={Aire}
          href={AIRE}
          title="Instalación aire acondicionado"
          desc=""
        />

      </GridContainer>
    </div>
  )
}

export default LandingServices
