import React from 'react'
import classNames from 'classnames'
import Layout from 'layout/layout'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Parallax from 'components/Parallax/Parallax.js'
import landingPageStyle from 'assets/jss/styles/pages/landingPageStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import LandingServices from 'pages-sections/landing/LandingServices'
import MantenimientoImg from "assets/img/sections/mantenimiento-electricista.jpg";

const useStyles = makeStyles(landingPageStyle);

const Mantenimiento = ({ data }) => {
  const classes = useStyles()

  return (
    <Layout
      pageTitle="🧯 Mantenimiento y averías eléctricas en Barcelona"
      pageDescription="✨ En suburled ofrecemos servicio de mantenimiento y averías eléctricas tanto para industria como para comercios, hoteles y restaurantes. Manteniemiento de instalaciones eléctricas industriales y comerciales."
      headerImage={MantenimientoImg}
      filter="dark"
      content={
        <React.Fragment>
          <h1 className={classes.typist}>Mantenimiento y averías eléctricas domésticas, industriales y comerciales</h1>

          <Link to="/presupuesto">
            <Button color={'primary'} className={classes.navButton}>
              Solicita Presupuesto
            </Button>
          </Link>

          <br />
        </React.Fragment>
      }
    >

      <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.mrBottom
          )}
        >
        <div dangerouslySetInnerHTML={{__html: data.mantenimientoJson.content.childMarkdownRemark.html}} />
      </GridItem>
  
      <LandingServices classes={classes} />

    </Layout>
  )  
}

export default Mantenimiento;

export const query = graphql`
  query MantenimientopageQuery {
    mantenimientoJson {
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;